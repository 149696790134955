import { gql } from "@apollo/client";
import { ADDRESS_FRAGMENT } from "@shared/gql/fragments";

export const CART_BADGE_GQL_DATA = gql`
  query CartBadge($sessionId: String, $userId: String) {
    cart(sessionId: $sessionId, userId: $userId) { cartId itemsCount }
  }
`;

export const FULL_CART_GQL_DATA = gql`
  query UserCart($sessionId: String, $userId: String) {
    cart(sessionId: $sessionId, userId: $userId) {
      cartId
      itemsCount
      items {
        ... on RentalAccommodationCartItem {
          itemId title description image expirationDate period price { bare discount options { type amount } amount downPayment }
        }
      }
      fullPrice { amount discount downPayment }
    }
  }
`;

export const FULL_AGENCY_GQL_DATA = gql`
  ${ADDRESS_FRAGMENT}
  query Agency($agencyId: ID!) {
    agency: cmsAgency(agencyId: $agencyId) {
      id slug name nameComplement location { ...AddressFields } phone fax email website logo lead { value } coordinates
    }
  }
`;
